import React from "react"

import { Wrapper, Toggle, Icon, Title, Content } from "./AccordionStyles"

interface Props {
  active: boolean
  children: any
  condensed: boolean
  id: string
  setActive: Function
  small: boolean
  title: string
}

export const Accordion = ({ active, children, condensed, id, setActive, small, title }: Props) => (
  <Wrapper small={small} condensed={condensed}>
    <Toggle onClick={() => setActive(!active ? id : null)}>
      <Icon active={active} condensed={condensed} />
      <Title condensed={condensed} small={small}>
        {title}
      </Title>
    </Toggle>

    <Content active={active} condensed={condensed}>
      {children}
    </Content>
  </Wrapper>
)
