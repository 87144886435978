import React from "react"

import { withCartFormInformation } from "./withCartFormInformation"
import { Accordion } from "../../Accordion/Accordion"
import { RichText } from "../../RichText/RichText"
import { InformationContent } from "../CartStyles"

interface Props {
  active: boolean
  items: Array<any>
  setActive: Function
}

export const CartFormInformation = withCartFormInformation(({ active, items, setActive }: Props) => (
  <>
    {items.map(item => (
      <Accordion key={item.id} id={item.id} condensed active={active === item.id} title={item.title} small setActive={setActive}>
        <InformationContent>
          <RichText content={item.content} />
        </InformationContent>
      </Accordion>
    ))}
  </>
))
