import React, { useEffect, useState } from "react"

import { useApp } from "@hooks/useApp"
import { useCart } from "@hooks/useCart"
import { useCheckout, useCheckoutContext } from "@hooks/useCheckout"
import { useCustomerDetails } from "@hooks/useCustomer"
import { useLanguage } from "@hooks/useLanguage"
import { usePaymentData } from "@hooks/usePayments"
import { useSanity } from "@hooks/useSanity"
import { useShopify } from "@hooks/useShopify"

export const withCart = Component => ({ name = `Cart`, page }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [onedaypayActive, setOnedaypayActive] = useState(false)
  const {
    config: { settings },
  } = useApp()
  const { excludeGroupedAndWrappingItems, getGroupedItems } = useCart()
  const { checkout } = useCheckoutContext()
  const { count } = useCheckout()
  const locales = useLanguage(`cart`)
  const { usePopularProducts } = useShopify()
  const { textNormaliser } = useSanity()
  const products = usePopularProducts({ max: 4 })
  const { customPaymentEnabled, customPaymentUsers, customPaymentDescription } = usePaymentData()
  const { customer } = useCustomerDetails()

  const content = {
    ...page,
    items:
      page?._rawItems?.length > 0
        ? page._rawItems.map(item => ({
            title: textNormaliser(item.title),
            content: textNormaliser(item.content),
          }))
        : [],
  }
  const items = excludeGroupedAndWrappingItems(checkout?.lines)
  const hasLineItems = count > 0

  useEffect(() => {
    if (customPaymentEnabled) {
      setOnedaypayActive(true)
    } else if (customPaymentUsers && Array.isArray(customPaymentUsers)) {
      const pattern = customPaymentUsers.join("|")
      const isExemptEmail = customer?.email?.match(new RegExp(pattern, "gi"))
      setOnedaypayActive(!!isExemptEmail)
    }
  }, [customer, customPaymentEnabled])

  Component.displayName = name
  return (
    <Component
      checkout={checkout}
      content={content}
      customPaymentDescription={customPaymentDescription}
      getGroupedItems={getGroupedItems}
      hasLineItems={hasLineItems}
      items={items}
      locales={locales}
      onedaypayActive={onedaypayActive}
      products={products}
      setShowPopup={setShowPopup}
      settings={settings}
      showPopup={showPopup}
    />
  )
}
