import React from "react"

import { withProductGrid } from "./withProductGrid"
import { SearchItem } from "../../Search/Item/SearchItem"
import { Row, Section, StyledH5 } from "./ProductGridStyles"

interface Props {
  products: Array<any>
  title: string
}

export const ProductGrid = withProductGrid(({ products, title }: Props) =>
  products?.length > 0 ? (
    <Section>
      {title && <StyledH5>{title}</StyledH5>}

      <Row>
        {products.map((product, index) => (
          <SearchItem key={product.id} index={index} product={product} screenWidth width={`1/4`} />
        ))}
      </Row>
    </Section>
  ) : null
)

export default ProductGrid
