import styled from "@emotion/styled"
import tw from "twin.macro"

import ArrowRight from "static/icons/theme/arrow-right.svg"

import { Styles } from "../Styled/Text"

export const Wrapper = styled.div`
  ${props => (props.condensed ? tw`pt-1-8` : tw`border-b border-grey-DEFAULT pb-1-2 mb-1-2`)}
  ${props => (props.small ? tw`border-b border-grey-DEFAULT pb-1-6` : ``)}
`
export const Toggle = tw.button`w-full flex justify-start items-start cursor-pointer focus:outline-none`
export const Icon = styled(ArrowRight)`
  ${tw`block w-0-8 h-0-8 mr-1-2 transform transition-all duration-200`}
  ${props => (props.active ? tw`rotate-90` : tw`rotate-0`)}
  ${props => (props.condensed ? tw`mb-0-2` : tw`mt-0-6`)}
`
export const Title = styled.p`
  ${tw`text-left`}
  ${props => (props.condensed || props.small ? Styles.Label : Styles.P)}
  ${props => (props.small ? tw`text-xms` : ``)}
`
export const Content = styled.div`
  ${tw`pl-2 mt-0-2`}
  ${props => (props.active ? tw`block` : tw`hidden`)}
  ${props => (props.condensed ? tw`` : tw`mt-2-4`)}
`
