import React, { useState } from "react"

import { useCore } from "@hooks/useCore"

export const withCartFormInformation = Component => ({ name = `CartFormInformation`, page }) => {
  const {
    helpers: { handleize },
  } = useCore()
  const [active, setActive] = useState(null)

  const items = page.items.map(item => ({
    ...item,
    id: handleize(item.title),
  }))

  Component.displayName = name
  return <Component active={active} items={items} setActive={setActive} />
}
