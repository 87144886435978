import React from "react"

import { useAnalytics } from "@hooks/useAnalytics"
import { useShopify } from "@hooks/useShopify"

export const withProductGrid = Component => ({ name = `ProductGrid`, handles, items, section, title }) => {
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()
  const { useProducts } = useShopify()
  const fetchedProducts = useProducts({ handles: handles || [], firstImages: 2, firstMedia: 0, firstVariants: 1 })
  const products = items ? items : fetchedProducts

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...(section?.analytics || {}) })} partialVisibility={true}>
      <Component products={products} title={title} />
    </VisibilitySensor>
  )
}
